<template>
  <section class="menu bg-gray-100 py-16" id="menu">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Unser Menü</h2>
        <p class="mt-4 text-lg md:text-xl text-gray-600">
          Entdecke unsere vielfältigen Kaffeespezialitäten.
        </p>
      </div>

      <!-- Menu Image Section -->
      <div class="flex justify-center">
        <img src="@/assets/menu.jpg" alt="Menu Bild" class="rounded-lg shadow-lg w-full md:w-1/3 h-auto object-cover">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MenuSection'
};
</script>

<style scoped>
/* Kein zusätzliches CSS erforderlich, da Tailwind-Klassen verwendet werden */
</style>
