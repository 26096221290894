<template>
  <l-map
    class="map-custom-height"
    :zoom="15"
    :center="[52.499219, 13.444658]"
    style="width: 100%;"
  >
    <l-tile-layer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
    <l-marker :lat-lng="[52.499219, 13.444658]"></l-marker>
  </l-map>
</template>


<script>
export default {
  name: 'MapComponent',
};
</script>

<style scoped>
/* Optional: Weitere Anpassungen für das Styling der Karte */
.map-container {
  border-radius: 8px; /* Abgerundete Ecken für eine bessere Optik */
}
</style>
