<template>
  <section class="impressum-section bg-gray-100 py-16 mt-16" id="impressum">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Impressum</h2>
      </div>

      <!-- Text Content -->
      <div class="text-lg text-gray-700 space-y-4">
        <!-- Hier den Impressum-Text einfügen -->
        <p>Dein Impressumstext hier...</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImpressumSection'
};
</script>

<style scoped>
.impressum-section {
  background-color: #f4f4f4;
}
</style>
