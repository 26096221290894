<template>
  <section class="contact py-16 bg-gray-100" id="contact">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-4xl font-bold text-center text-gray-800 mb-8">Kontakt</h2>
      <p class="text-center text-lg text-gray-600 mb-12">
        Besuche uns oder kontaktiere uns für mehr Informationen.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Kontaktformular -->
        <div>
          <form class="bg-white shadow-md rounded-lg px-8 py-6">
            <div class="mb-4">
              <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Name</label>
              <input type="text" id="name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Dein Name" />
            </div>
            <div class="mb-4">
              <label for="email" class="block text-gray-700 text-sm font-bold mb-2">E-Mail</label>
              <input type="email" id="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Deine E-Mail" />
            </div>
            <div class="mb-4">
              <label for="message" class="block text-gray-700 text-sm font-bold mb-2">Nachricht</label>
              <textarea id="message" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="4" placeholder="Deine Nachricht"></textarea>
            </div>
            <div class="flex items-center justify-between">
              <button type="submit" class="bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Senden
              </button>
            </div>
          </form>
        </div>
        <!-- Kontaktinformationen -->
        <div class="bg-white shadow-md rounded-lg px-8 py-6">
          <h3 class="text-2xl font-bold text-gray-800 mb-4">Unsere Kontaktinformationen</h3>
          <div class="text-gray-700 mb-4">
            <p class="flex items-center mb-2">
              <strong class="mr-2">Adresse:</strong>
              Falckensteinstrasse 4, 10997 Berlin
            </p>
            <p class="flex items-center">
              <a href="https://g.co/kgs/usi8Cdx" target="_blank" class="text-teal-500 hover:text-teal-600 flex items-center">
                <img src="@/assets/google-maps-icon.svg" alt="Google Maps" class="w-5 h-5 mr-2" />
                <span>Auf Google Maps ansehen</span>
              </a>
            </p>
          </div>
          <p class="text-gray-700 mb-4">
            <strong>Telefon:</strong> +49 30 12345678
          </p>
          <p class="text-gray-700 mb-4">
            <strong>E-Mail:</strong> info@classiccoffee.de
          </p>
          <p class="text-gray-700">
            <strong>Öffnungszeiten:</strong><br>
            Montag - Freitag: 8:30 - 18:00<br>
            Samstag - Sonntag: 10:00 - 18:00
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
};
</script>

<style scoped>
/* Kein zusätzliches CSS erforderlich, da Tailwind-Klassen verwendet werden */
</style>
