<template>
  <section class="widerrufsbelehrung-section bg-gray-100 py-16 mt-16" id="widerrufsbelehrung">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Widerrufsbelehrung</h2>
      </div>

      <!-- Text Content -->
      <div class="text-lg text-gray-700 space-y-4">
        <!-- Hier den Widerrufsbelehrungstext einfügen -->
        <p>Dein Widerrufsbelehrungstext hier...</p>

        <p>
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
          Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter,
          der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
        </p>

        
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WiderrufsbelehrungSection'
};
</script>

<style scoped>
.widerrufsbelehrung-section {
  background-color: #f4f4f4;
}
</style>
