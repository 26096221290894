// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Importiere den Router

import './main.css';
import './assets/tailwind.css';
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';

const app = createApp(App);

app.use(router); // Verwende den Router

app.component('LMap', LMap);
app.component('LTileLayer', LTileLayer);
app.component('LMarker', LMarker);

app.mount('#app');
