// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import DatenschutzSection from '@/components/DatenschutzSection.vue';
import WiderrufsbelehrungSection from '@/components/WiderrufsbelehrungSection.vue';
import AGBSection from '@/components/AGBSection.vue';
import ImpressumSection from '@/components/ImpressumSection.vue';
import Home from '@/components/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: DatenschutzSection
  },
  {
    path: '/widerrufsrecht',
    name: 'Widerrufsrecht',
    component: WiderrufsbelehrungSection
  },
  {
    path: '/agb',
    name: 'AGB',
    component: AGBSection
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: ImpressumSection
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { 
        el: to.hash, 
        top: 40, // Offset in Pixeln, z.B. 80px
        behavior: 'smooth'
      };
    }
  }
});

export default router;
