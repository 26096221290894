<template>
  <section class="gallery bg-white py-16" id="gallery">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Galerie</h2>
        <p class="mt-4 text-lg md:text-xl text-gray-600">
          Einblicke in unsere Kaffee-Welt.
        </p>
      </div>

      <!-- Gallery Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <!-- First Row of Images -->
        <div>
          <img src="@/assets/Galerie/image1.jpg" alt="Gallery Image 1" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image2.jpg" alt="Gallery Image 2" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image3.jpg" alt="Gallery Image 3" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image4.jpg" alt="Gallery Image 4" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>

        <!-- Second Row of Images -->
        <div>
          <img src="@/assets/Galerie/image5.jpg" alt="Gallery Image 5" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image6.jpg" alt="Gallery Image 6" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image7.jpg" alt="Gallery Image 7" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
        <div>
          <img src="@/assets/Galerie/image8.jpg" alt="Gallery Image 8" class="w-full h-64 object-cover rounded-lg shadow-md">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Gallery'
};
</script>

<style scoped>
.gallery {
  padding: 50px;
}
</style>
