<template>
  <section class="bohnen-section bg-gray-100 py-16" id="bohnen">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Was macht Specialty Coffee so besonders?</h2>
      </div>

      <!-- Content Section -->
      <div class="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-20">
        <!-- Text Content -->
        <div class="w-full md:w-1/3 text-center md:text-left">
          <p class="text-lg text-gray-700 mb-4">
            Specialty Coffee steht für höchste Qualität, die durch sorgfältige Auswahl und nachhaltige Anbaumethoden erreicht wird. 
            Er wird von der Specialty Coffee Association (SCA) mit über 80 von 100 Punkten bewertet und zeichnet sich durch sein 
            einzigartiges Aroma, intensive Geschmacksnoten und eine perfekt ausbalancierte Säure aus.
          </p>
          <p class="text-lg text-gray-700">
            Bei uns erwartet Sie ein außergewöhnliches Kaffeeerlebnis, bei dem jede Tasse die Leidenschaft 
            und das Engagement für die besten Bohnen widerspiegelt. Probieren Sie den Unterschied und genießen Sie ein unvergleichliches 
            Geschmackserlebnis!
          </p>
        </div>
        
        <!-- Image Section -->
        <div class="w-full md:w-1/3">
          <img src="@/assets/beans.jpg" alt="Bild von Kaffeebohnen" class="rounded-lg shadow-lg w-full h-full object-cover">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Bohnen'
};
</script>

<style scoped>
.bohnen-section {
  background-color: #f4f4f4;
}
</style>
