<!-- src/components/Home.vue -->
<template>
  <div>
    <HeroSection id="hero" />
    <AboutUs id="about" />
    <Bohnen id="bohnen" />
    <Gallery id="gallery" />
    <MenuSection id="menu" />
    <ContactSection id="contact" />
    <Shop id="Shop" />
  </div>
</template>

<script>
import HeroSection from './HeroSection.vue';
import AboutUs from './AboutUs.vue';
import Bohnen from './Bohnen.vue';
import Gallery from './Gallery.vue';
import MenuSection from './MenuSection.vue';
import ContactSection from './ContactSection.vue';
import Shop from './Shop.vue';


export default {
  name: 'Home',
  components: {
    HeroSection,
    AboutUs,
    Bohnen,
    Gallery,
    MenuSection,
    ContactSection,
    Shop
  }
};
</script>

<style>
/* Stile für Home-Seite */
</style>
