<template>
  <section class="relative overflow-hidden h-screen flex items-center justify-center">
    <div class="flex transition-transform duration-500" :style="{ transform: 'translateX(' + (-currentSlide * 100) + '%)' }">
      <div class="min-w-full h-screen relative" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.title" class="w-full h-full object-cover" />
        <div class="absolute inset-0 flex flex-col items-center justify-center text-white text-center bg-black bg-opacity-50">
          <h1 class="text-4xl md:text-6xl lg:text-7xl font-bold">{{ slide.title }}</h1>
          <p class="mt-4 text-lg md:text-xl lg:text-2xl">{{ slide.description }}</p>
        </div>
      </div>
    </div>
    <div class="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-4">
      <!-- Optional: Add control buttons here -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          image: require('../assets/slide1.jpg'),
          title: 'Genieße den besten Kaffee',
          description: 'Erlebe unvergleichlichen Kaffeegenuss in unserem Cafe.',
        },
        {
          image: require('../assets/slide2.jpg'),
          title: 'Gemütliche Atmosphäre',
          description: 'Entspanne bei einer Tasse Kaffee in gemütlicher Umgebung.',
        },
        {
          image: require('../assets/slide3.jpg'),
          title: 'Frische Bohnen',
          description: 'Wir rösten unsere Bohnen frisch für den besten Geschmack.',
        }
      ]
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    }
  },
  mounted() {
    // Timer starten, um die Slides alle 10 Sekunden zu wechseln
    setInterval(() => {
      this.nextSlide();
    }, 10000); // 10000 Millisekunden = 10 Sekunden
  }
};
</script>

<style scoped>
/* Kein zusätzliches CSS erforderlich, da Tailwind-Klassen verwendet werden */
</style>
