<template>
  <section id="shop" class="shop-section py-16 bg-white text-black text-center relative">
    <h2 class="text-4xl font-bold mb-8">Shop</h2>
    
    <!-- Overlay -->
    <div class="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center">
      <p class="text-white text-2xl mb-4">Bald startet hier unser neuer Online-Shop, wo du alles findest, was dein Kaffeeliebhaber-Herz begehrt. 
        <div>Vor allem unsere selbstgerösteten Kaffeebohnen.</div></p>
      <!-- Schloss Bild -->
      <img src="@/assets/schloss.png" alt="Schloss Symbol" class="mt-4 w-16 h-16" />
    </div>
    
    <!-- Produktbilder -->
    <div class="product-images flex justify-center space-x-4 mt-12">
      <div class="product-image" v-for="n in 3" :key="n">
        <img src="@/assets/mockup.png" alt="Produkt Bild" class="rounded-lg shadow-lg w-72 h-78 object-cover" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Shop",
};
</script>

<style scoped>
/* Keine zusätzlichen Styles benötigt, Tailwind deckt alles ab */
</style>
